/* eslint-disable max-len */
import builderPathInit from '../util/builder-path-init';
import newsClipsBuilderConfig from '../components/news-clips/news-clips.builder.config';
import NewsClipsWidget from '../components/news-clips/news-clips.builder';
import featuresDisplayBuilderConfig from '../components/features-display/features-display.builder.config';
import FeaturesDisplayWidget from '../components/features-display/features-display.builder';
import uniqueCreationsBuilderConfig from '../components/unique-creations/unique-creations.builder.config';
import UniqueCreationsWidget from '../components/unique-creations/unique-creations.builder';
import signupWidgetBuilderConfig from '../components/signup-builder/signup-widget.builder.config';
import SignupWidgetBuilder from '../components/signup-builder/signup-widget';
import signupModalBuilderConfig from '../components/signup-builder/signup-modal.builder.config';
import SignupModalBuilder from '../components/signup-builder/signup-modal';

const { BuilderPage, getServerSideProps } = builderPathInit({
  model: 'page',
  pathPrefix: '',
  components: [
    {
      config: newsClipsBuilderConfig,
      component: NewsClipsWidget,
    },
    {
      config: featuresDisplayBuilderConfig,
      component: FeaturesDisplayWidget,
    },
    {
      config: uniqueCreationsBuilderConfig,
      component: UniqueCreationsWidget,
    },
    {
      config: signupWidgetBuilderConfig,
      component: SignupWidgetBuilder,
    },
    {
      config: signupModalBuilderConfig,
      component: SignupModalBuilder,
    },
  ],
});

export default BuilderPage;
export { getServerSideProps };
